import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { RegionEntityList } from '@modules/shared/entities';
import { GetRegionsQuery } from '@modules/shared/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetRegionsQueryType, GetRegionsQueryVariables } from '@modules/types/graphql';

const useRegions = (options?: QueryHookOptions<GetRegionsQueryType, GetRegionsQueryVariables>) => {
    const queryResult = useQuery<GetRegionsQueryType, GetRegionsQueryVariables>(
        GetRegionsQuery,
        options,
    );

    const { entities: regions, count } = useEntity(
        () => new RegionEntityList(queryResult.data?.getRegions ?? null),
        [queryResult.data],
    );

    const result = {
        regions,
        count,
        ...queryResult,
    };

    return result;
};

export { useRegions };
