import { EntityList } from '@lib/entity';
import { LocationEntity } from './location-entity';

import type { Entity } from './location-entity';

class LocationEntityList extends EntityList<LocationEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LocationEntity, items, count);
    }
}

export { LocationEntityList };
